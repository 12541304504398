.gizmo {
    padding-bottom: 80px;
    min-width: 290px;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-bottom:90px;
}

.gizmo--blog {
    padding-bottom: 0; 
    padding-top: 80px
}

.gadget {
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    margin-bottom: 30px;
    padding: 20px
}

.gadget img {
    display: block;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.gadget-title {
    margin-bottom: 30px;
    text-transform: capitalize
}

.gadget--search {
    margin-bottom: 30px
}

.gadget--search .btn i {
    font-size: 24px
}

.gadget--search .field {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 75%;
    padding: 16px 30px;
    border: 1px solid #c8c8c8;
    border-right: none;
    font-size: inherit;
    line-height: inherit
}

.gadget--search .field::-webkit-input-placeholder {
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    color: #a9a9a9
}

.gadget--search .field::-moz-placeholder {
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    color: #a9a9a9
}
.gadget--search .field:-ms-input-placeholder {
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    color: #a9a9a9
}

.gadget--search .field::-ms-input-placeholder {
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    color: #a9a9a9
}

.gadget--search .field::placeholder {
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    color: #a9a9a9
}

.gadget--search .field:focus::-webkit-input-placeholder {
    opacity: 0
}

.gadget--search .field:focus::-moz-placeholder {
    opacity: 0
}

.gadget--search .field:focus:-ms-input-placeholder {
    opacity: 0
}

.gadget--search .field:focus::-ms-input-placeholder {
    opacity: 0
}

.gadget--search .field:focus::placeholder {
    opacity: 0
}

.gadget--latest .list .list-item {
    padding-bottom: 15px;
    font-size: 18px;
    line-height: 23px
}

.gadget--latest .list .list-item .title {
    margin: 15px 0 8px;
    font-weight: inherit;
    font-size: 13px;
    line-height: 1.2
}

.gadget--archives,.gadget--categories,.gadget--latest {
    font-family: Archivo,sans-serif;
    font-weight: 600
}

.gadget--archives,.gadget--categories,.gadget--tags {
    text-transform: uppercase
}

.gadget--categories .list .list-item {
    padding-bottom: 15px;
    margin-top: 15px
}

.gadget--categories .list .list-item .link {
    font-size: 18px;
    line-height: 23px;
    -webkit-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out
}

.gadget--categories .list .list-item .link:hover {
    color: #de133b
}


.gadget--newsletter .gadget-title {
    margin: 0
}

.gadget--newsletter .text {
    margin: 20px 0 30px;
    color: #202c38
}

.gadget--newsletter .form .field {
    border: 1px solid #a9a9a9;
    padding: 17px 19px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 20px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.gadget--newsletter .form .field::-webkit-input-placeholder {
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.gadget--newsletter .form .field::-moz-placeholder {
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.gadget--newsletter .form .field:-ms-input-placeholder {
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.gadget--newsletter .form .field::-ms-input-placeholder {
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.gadget--newsletter .form .field::placeholder {
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.gadget--newsletter .form .field:focus {
    border-color: #de133b
}

.gadget--newsletter .form .field:focus::-webkit-input-placeholder {
    opacity: 0
}

.gadget--newsletter .form .field:focus::-moz-placeholder {
    opacity: 0
}

.gadget--newsletter .form .field:focus:-ms-input-placeholder {
    opacity: 0
}

.gadget--newsletter .form .field:focus::-ms-input-placeholder {
    opacity: 0
}

.gadget--newsletter .form .field:focus::placeholder {
    opacity: 0
}

.gadget--newsletter .form .btn {
    width: 100%
}

.gadget--newsletter .mediaLink {
    margin-top: 40px
}

.gadget--tags .list .list-item .tag {
    border: 1px solid #de133b;
    padding: 10px 20px;
    display: block;
    margin-top: 20px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 22px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.gadget--tags .list .list-item .tag:focus,.gadget--tags .list .list-item .tag:hover {
    border-color: #001f3f;
    -webkit-transform: scale(.9);
    -ms-transform: scale(.9);
    transform: scale(.9)
}

.gadget--archives .list .list-item {
    margin-bottom: 20px
}

.gadget--archives .list .list-item:last-of-type {
    margin-bottom: 0
}

.gadget--archives .list .list-item .link {
    font-size: 18px;
    line-height: 23px
}

.gadget--archives .list .list-item .link:focus .icon--arrow,.gadget--archives .list .list-item .link:hover .icon--arrow {
    color: #de133b
}

.gadget--archives .list .list-item .link .icon--arrow {
    -webkit-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out
}

/* Media queries*/
@media screen and (min-width:575.98px) {
    .gadget {
        padding: 30px
    }
    .gadget--newsletter .form {
        max-width: 320px
    }

    .gadget--newsletter .form .field {
        border-right: none;
        margin-bottom: 0
    }

    .gadget--newsletter .form .btn {
        min-width: 130px;
        padding: 18px 0
    }
}

@media screen and (min-width:767.98px) {
    .gizmo {
        padding: 80px 0
    }

    .gizmo--blog {
        padding: 80px 0 0
    }
}

@media screen and (min-width:991.98px) {
    .gizmo {
        max-width: 380px;
        -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
        order: unset;
        padding: 0
    }

    .gadget {
        margin-bottom: 40px;
        padding: 40px 30px
    }

    .gadget--search {
        margin-bottom: 40px
    }

    .gadget--newsletter .form {
        max-width: 100%
    }

    .gadget--newsletter .form .field {
        max-width: 186px
    }

    .gadget--newsletter .form .btn {
        width: 131px;
        padding: 18px 0
    }
}
