.formatDisplayer--products{
    margin-top:150px;
    margin-bottom:150px;
}
.formatDisplayer--products .main-img{
    width:100%;
    height:400px;
}
.formatDisplayer--products .my-5{
    margin-bottom:50px;
}
.formatDisplayer--products .text-bold {
    font-weight: 800;
}

.formatDisplayer--products text-color {
    color: #c9c9c9;
}

/* Main image - left */
.formatDisplayer--products .main-img img {
    width: 100%;
}

/* Preview images */
.formatDisplayer--products .previews img {
    width: 100%;
    height: 140px;
}

.formatDisplayer--products .main-description .category {
    text-transform: uppercase;
    color: #0093c4;
}

.formatDisplayer--products .main-description .product-title {
    font-size: 2.5rem;
}

.formatDisplayer--products .old-price-discount {
    font-weight: 600;
}

.formatDisplayer--products .new-price {
    font-size: 2rem;
}

.formatDisplayer--products .details-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2rem;
    color: #757575;
}

.formatDisplayer--products .buttons .block {
    margin-right: 5px;
}

.formatDisplayer--products .quantity input {
    border-radius: 0;
    height: 40px;

}
.formatDisplayer--products .description{
    font-size:15px;
    margin-top:20px;
    margin-bottom:40px;
}

.formatDisplayer--products .custom-btn {
    text-transform: capitalize;
    background-color: #0093c4;
    color: white;
    width: 150px;
    height: 40px;
    border-radius: 0;
}

.formatDisplayer--products .custom-btn:hover {
    background-color: #0093c4 !important;
    font-size: 18px;
    color: white !important;
}

.formatDisplayer--products .similar-product img {
    height: 400px;
}

.formatDisplayer--products .similar-product {
    text-align: left;
}

.formatDisplayer--products .similar-product .title {
    margin: 17px 0px 4px 0px;
}

.formatDisplayer--products .similar-product .price {
    font-weight: bold;
}

.formatDisplayer--products .questions .icon i {
    font-size: 2rem;
}

.formatDisplayer--products .questions-icon {
    font-size: 2rem;
    color: #0093c4;
}


/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

    /* Make preview images responsive  */
    .formatDisplayer--products .previews img {
        width: 100%;
        height: auto;
    }

    .formatDisplayer--products .detailsContainer{
        margin-top:300px;
    }

}

.formatDisplayer--products .btn-success{
    margin-top:30px;
    margin-bottom:30px;
}

