/* Styles for the gallery items container */
.galleryContainer > .col-lg-3 {
    padding: 0;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
  }
  
  .galleryContainer > .col-lg-3 > div {
    padding: 50px;
    padding-top: 140px;
    color: white;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    width: 100%;
    height: 100%;
  }
  
  .galleryContainer > .col-lg-3 > div .lineTitle {
    margin-bottom: 20px;
  }
  
  .galleryContainer > .col-lg-3 > div h4 {
    margin-bottom: 20px;
  }
  
  .text-warning {
    color: #de133b;
  }
  
  .visible-gallery {
    opacity: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .invisible-gallery {
    opacity: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  