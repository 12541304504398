.userPfp{
    height:30px;
    width:30px;
    border-radius:50%;
    margin-right:20px;
}
.signOutButton{
    height:40px;
    display:flex;
    align-items:center;
    margin-left:20px;
}
@media screen and (max-width:991.98px){
    .userName{
        display:none;
    }
    .signOutButton{
        width:100%;
    }
}