.smlNav{
    position:fixed;
    top:0;
    right:0;
    left:0;
    transform-origin: top;
    transition:.25s ease-in-out;
    z-index: 4;
    bottom:0;
}
@media screen and (min-width:991.98px){
    .smlNav{
        display:none;
    }
}