.gallery_header {
    margin-bottom: 30px
}
.d-grid {
    display: grid!important
}

.gallery_grid {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 30px
}

.gallery_grid-item {
    max-height: 470px
}

.gallery_grid-item_trigger {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative
}

.gallery_grid-item_trigger .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 20px;
    color: #fff;
    background: rgba(0,31,63,.5);
    opacity: 0;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.gallery_grid-item_trigger .overlay_caption {
    font-family: Archivo,sans-serif;
    font-weight: 700
}

.gallery_grid-item_trigger .overlay_label {
    text-transform: uppercase;
    color: #de133b;
    margin-top: 20px;
    font-family: Archivo,sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px
}

.gallery_grid-item_trigger:hover .overlay {
    opacity: 1
}

.gallery_grid-item_img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.galleryPart .clickableImage .lightPurplishFade,.unLightPurplishFade,.galleryPart .clickableImage .clear{
    display:flex;
    flex-flow:column;
    justify-content: center;
    align-items:center;
    padding:50px;
    padding-top:100px;
    padding-bottom:100px;
    margin-top:20px;
}
.galleryPart .clickableImage{
    background-position: center;
    cursor:pointer;
}
.galleryPart .clickableImage h3{
    color:white;
}
.galleryPart  button{
    width:100%;
    margin-top:20px;
    font-weight:bold;

}
@media screen and (max-width:1200px){
    .lightPurplishFade,.unLightPurplishFade{
        padding-left:10px !important;
        padding-right:10px !important;
    }
    .galleryPart .clickableImage button{
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width:991.98px) {
    .gallery_header {
        margin-bottom: 40px
    }

    .gallery_grid-item_trigger .overlay {
        padding: 40px
    }
}
@media screen and (min-width:575.98px) {
    .gallery_grid {
        grid-template-columns: repeat(2,1fr)
    }
}
@media screen and (min-width:767.98px) {
    .gallery_grid {
        grid-template-columns: repeat(3,1fr)
    }
}


  .galleryPart .clickableImage {
    position: relative;
    cursor: pointer;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    
  }
  

  .galleryPart .clickableImage .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #fff;
    background: rgba(0, 31, 63, 0.7);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .galleryPart .clickableImage h3 {
    color: white;
    font-weight: 600;
    font-size: 18px;
    margin-top: 20px;
  }
  
  
  
  @media screen and (max-width: 767px) {
    .gallery_grid {
      grid-template-columns: 1fr;
    }
  }
  

  .lightPurplishFade {
    background: rgba(0, 31, 63, 0.7);
    transition: 0.5s ease-in-out;
  }
  
  .unLightPurplishFade {
    background: rgba(0, 31, 63, 0);
    opacity: 0;
    transition: 0.5s ease-in-out;
  }
  

  