
.headerFlex-dashboard{
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding:20px;
}
.iconContainer-dashboard > *{
    font-size:30px;
    cursor:pointer;
}
.iconContainer-dashboard{
    margin-top:10px;
}
.headerFlex-dashboard li p{
    font-size:15px;
}

@media screen and (min-width:991.98px){
    .iconContainer-dashboard{
        display:none;
    }
}