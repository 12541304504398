.process > .container{
    margin-bottom:50px;
    margin-top:-50px;
}
.process_header {
    max-width: 376px
}

.process_text {
    max-width: 520px;
    margin: 30px 0 40px;
    color: #ffffff
}

.process_steps {
    padding-right: 15px
}

.process_steps-step {
    position: relative
}

.process_steps-step_wrapper {
    margin-left: 20px;
    padding-left: 20px
}

.timeStamp h3{
    margin-bottom:20px;
}
.process_steps-step:last-of-type {
    margin-bottom: 0
}

.process_steps-step .title {
    margin-bottom: 10px
}

.process_steps-step .description {
    padding-bottom: 30px
}

@media screen and (min-width:991.98px) {
    .process_text {
        margin: 0
    }

    .process_fluid {
        position: relative
    }

    .process_fluid:before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 10px;
        background: #a9a9a9
    }

    .process_steps {
        padding-right: 0;
        margin-top: 60px
    }

    .process_steps-step {
        -webkit-box-flex: unset;
        -ms-flex: unset;
        flex: unset
    }

    .process_steps-step_wrapper {
        margin-left: 0;
        padding-left: 10px;
        margin-top: 60px;
        max-width: 298px;
        margin-right: 32px
    }

    .process_steps-step:last-of-type {
        margin-bottom: 0
    }

    .process_steps-step .description {
        padding-bottom: 0
    }
}

.progress-tracker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    list-style: none
}

.progress-step {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    margin: 0;
    padding: 0;
    min-width: 24px
}

.progress-step:last-child {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0
}

.progress-step:last-child .progress-marker::after {
    display: none
}

.progress-marker {
    display: block;
    position: relative;
}

.progress-marker_spot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    background-color: #de133b;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3
}

.progress-marker_spot--underlay {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: rgba(255,198,49,.3);
    top: -10px;
    left: -10px;
    z-index: 2;
    transition: ease-in-out
}

.progress-step.is-complete .progress-marker_spot--underlay {
    animation: 4.5s infinite pulse;
    width: 40px;
    height: 40px
}

.progress-marker::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: -10px;
    width: 100%;
    height: 1px;
    -webkit-transition: background-color .6s,background-position .6s;
    transition: background-color .6s,background-position .6s
}

.progress-step .progress-marker {
    color: #a9a9a9
}

.progress-step .progress-marker::after,.progress-step .progress-marker::before {
    background-color: #b6b6b6
}

.progress-step .progress-text {
    color: #333
}

.progress-step.is-active .progress-marker::before,.progress-step.is-complete .progress-marker::after,.progress-step.is-complete .progress-marker::before,.progress-step.is-progress .progress-marker::after,.progress-step.is-progress .progress-marker::before {
    background-color: #de133b
}

.progress-step.is-progress-10 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(10%,#868686),color-stop(10%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 10%,#b6b6b6 10%)
}

.progress-step.is-progress-20 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(20%,#868686),color-stop(20%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 20%,#b6b6b6 20%)
}

.progress-step.is-progress-30 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(30%,#868686),color-stop(30%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 30%,#b6b6b6 30%)
}

.progress-step.is-progress-40 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(40%,#868686),color-stop(40%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 40%,#b6b6b6 40%)
}

.progress-step.is-progress-50 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#868686),color-stop(50%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 50%,#b6b6b6 50%)
}

.progress-step.is-progress-60 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(60%,#868686),color-stop(60%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 60%,#b6b6b6 60%)
}

.progress-step.is-progress-70 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(70%,#868686),color-stop(70%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 70%,#b6b6b6 70%)
}

.progress-step.is-progress-80 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(80%,#868686),color-stop(80%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 80%,#b6b6b6 80%)
}

.progress-step.is-progress-90 .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(90%,#868686),color-stop(90%,#b6b6b6));
    background-image: linear-gradient(to right,#868686 90%,#b6b6b6 90%)
}

.progress-step:hover .progress-marker::before {
    background-color: #56adf5
}

.progress-tracker--center .progress-step:last-child,.progress-tracker--right .progress-step:last-child,.progress-tracker--text .progress-step:last-child {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.progress-tracker--center {
    text-align: center
}

.progress-tracker--center .progress-marker::before,.progress-tracker--center .progress-text--dotted::before {
    margin-left: auto;
    margin-right: auto
}

.progress-tracker--center .progress-marker::after {
    right: -50%
}

.progress-tracker--right {
    text-align: right
}

.progress-tracker--right .progress-marker::before,.progress-tracker--right .progress-text--dotted::before {
    margin-left: auto
}

.progress-tracker--right .progress-marker::after {
    right: calc(-100% + 12px)
}

.progress-tracker--spaced .progress-marker::after {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px
}

.progress-tracker--border {
    padding: 4px;
    border: 2px solid #333;
    border-radius: 32px
}

.progress-text--dotted::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    margin: 6px -2px;
    background-size: 12px 18px;
    background-image: repeating-radial-gradient(circle at center 6px,#b6b6b6,#b6b6b6 5px,rgba(182,182,182,.5) 5.5px,rgba(182,182,182,.01) 6px,transparent 100%)
}

.progress-text--dotted-1::before {
    height: 12px
}

.progress-text--dotted-2::before {
    height: 30px
}

.progress-text--dotted-3::before {
    height: 48px
}

.progress-text--dotted-4::before {
    height: 66px
}

.progress-text--dotted-5::before {
    height: 84px
}

.progress-text--dotted-6::before {
    height: 102px
}

.progress-text--dotted-7::before {
    height: 120px
}

.progress-text--dotted-8::before {
    height: 138px
}

.progress-text--dotted-9::before {
    height: 156px
}

.progress-text--dotted-10::before {
    height: 174px
}

.progress-text--dotted-11::before {
    height: 192px
}

.progress-text--dotted-12::before {
    height: 210px
}

.progress-tracker--text-top .progress-text {
    height: 100%
}

.progress-tracker--text-top .progress-marker {
    top: -24px
}

.progress-tracker--text-inline {
    overflow: hidden
}

.progress-tracker--text-inline .progress-marker,.progress-tracker--text-inline .progress-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.progress-tracker--text-inline .progress-marker {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.progress-tracker--text-inline .progress-marker::after {
    top: auto
}

.progress-tracker--text-inline .progress-text {
    position: relative;
    z-index: 30;
    max-width: 70%;
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff
}

.progress-tracker--text-inline .progress-marker .progress-text {
    display: inline-block
}

.progress-tracker--text-inline .progress-title {
    margin: 0
}

.progress-tracker--square .progress-marker::before {
    border-radius: 0
}

.progress-tracker--square .progress-marker::after {
    top: auto;
    bottom: 0
}
.timeStamp>.col-lg-3{
    margin-top:80px;
}

@media (max-width:575px) {
    .progress-tracker-wrapper {
        overflow-x: auto;
        -ms-scroll-snap-type: x proximity;
        scroll-snap-type: x proximity
    }

    .progress-tracker-wrapper .progress-step {
        min-width: 50%;
        scroll-snap-align: start
    }
}

@media screen and (min-width:991.98px) {
    .process_steps{
        display: none !important;
    }
}
@media screen and (max-width:991.98px){
    .timeStamp{
        display:none !important;
    }
}
.progress-tracker--vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.progress-tracker--vertical .progress-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.progress-tracker--vertical.progress-tracker--right .progress-step {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.progress-tracker--vertical .progress-marker::after {
    right: auto;
    top: 0;
    left: 10px;
    width: 1px;
    height: 100%
}

.progress-tracker--vertical .progress-text {
    padding: 0 12px 24px
}
.anim-ripple .progress-link::before,.anim-ripple-large .progress-link::before,.anim-ripple-splash .progress-link::before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 30;
    background: rgba(0,0,0,.3);
    border-radius: 50%;
    -webkit-transform: translate(-50%,-50%) scale(0);
    transform: translate(-50%,-50%) scale(0);
    visibility: hidden;
    -webkit-animation: .3s ease-out scale-up;
    animation: .3s ease-out scale-up
}

.anim-ripple .progress-link:focus::before,.anim-ripple-large .progress-link:focus::before,.anim-ripple-splash .progress-link:focus::before {
    visibility: visible
}

.anim-ripple-double.progress-tracker--center .progress-link::after,.anim-ripple-double.progress-tracker--center .progress-link::before,.anim-ripple-large.progress-tracker--center .progress-link::after,.anim-ripple-large.progress-tracker--center .progress-link::before,.anim-ripple-splash.progress-tracker--center .progress-link::after,.anim-ripple-splash.progress-tracker--center .progress-link::before,.anim-ripple.progress-tracker--center .progress-link::after,.anim-ripple.progress-tracker--center .progress-link::before,.progress-tracker--center .anim-ripple .progress-link::after,.progress-tracker--center .anim-ripple .progress-link::before,.progress-tracker--center .anim-ripple-double .progress-link::after,.progress-tracker--center .anim-ripple-double .progress-link::before,.progress-tracker--center .anim-ripple-large .progress-link::after,.progress-tracker--center .anim-ripple-large .progress-link::before,.progress-tracker--center .anim-ripple-splash .progress-link::after,.progress-tracker--center .anim-ripple-splash .progress-link::before {
    left: 50%
}

.anim-ripple-double.progress-tracker--right .progress-link::after,.anim-ripple-double.progress-tracker--right .progress-link::before,.anim-ripple-large.progress-tracker--right .progress-link::after,.anim-ripple-large.progress-tracker--right .progress-link::before,.anim-ripple-splash.progress-tracker--right .progress-link::after,.anim-ripple-splash.progress-tracker--right .progress-link::before,.anim-ripple.progress-tracker--right .progress-link::after,.anim-ripple.progress-tracker--right .progress-link::before,.progress-tracker--right .anim-ripple .progress-link::after,.progress-tracker--right .anim-ripple .progress-link::before,.progress-tracker--right .anim-ripple-double .progress-link::after,.progress-tracker--right .anim-ripple-double .progress-link::before,.progress-tracker--right .anim-ripple-large .progress-link::after,.progress-tracker--right .anim-ripple-large .progress-link::before,.progress-tracker--right .anim-ripple-splash .progress-link::after,.progress-tracker--right .anim-ripple-splash .progress-link::before {
    left: calc(100% - 12px)
}

.anim-ripple-splash .progress-link::before {
    width: 48px;
    height: 48px;
    -webkit-box-shadow: 0 0 6px 6px rgba(0,0,0,.35);
    box-shadow: 0 0 6px 6px rgba(0,0,0,.35)
}

.anim-ripple-double .progress-link::after,.anim-ripple-double .progress-link::before {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 30;
    background: 0 0;
    border-radius: 50%;
    -webkit-transform: translate(-50%,-50%) scale(0);
    transform: translate(-50%,-50%) scale(0);
    visibility: hidden;
    border: 3px solid rgba(0,0,0,.3)
}

.anim-ripple-double .progress-link:not(:active)::after,.anim-ripple-double .progress-link:not(:active)::before {
    -webkit-animation: .3s ease-out scale-up;
    animation: .3s ease-out scale-up
}

.anim-ripple-double .progress-link:not(:active)::after {
    -webkit-animation-delay: .15s;
    animation-delay: .15s
}

.anim-ripple-double .progress-link:focus::after,.anim-ripple-double .progress-link:focus::before {
    visibility: visible
}

.anim--large .progress-link::after,.anim--large .progress-link::before {
    width: 48px;
    height: 48px
}

.anim--path .progress-marker::after {
    background-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#b6b6b6),color-stop(50%,#868686));
    background-image: linear-gradient(to right,#b6b6b6 50%,#868686 50%);
    background-size: 200% 100%;
    background-position: 0 100%;
    -webkit-transition: background-position .3s ease-out;
    transition: background-position .3s ease-out
}

.anim--path .progress-step.is-complete .progress-marker::after,.progress-step.is-complete .anim--path .progress-marker::after {
    background-position: -100% 100%
}

[dir=rtl] .progress-marker::after {
    right: auto;
    left: -12px
}

[dir=rtl] .progress-tracker--center .progress-marker::after {
    left: -50%
}
