.demoSlider>.purplishFade{
    padding-top:220px;
    padding-bottom:30px;
    padding-left:6%;
    padding-right:6%;
}
.demoSlider>*{
    color:white;
}
.circle{
    height:20px;
    width:20px;
    background:grey;
    margin-top:20px;
    border-radius:50%;
    cursor:pointer;
}
.selected-circle{
    background:#de133b !important;
}
.sliderPagination{
    margin-top:30px;
    width:100%;
}
.sliderContainer>*{
    color:white;
}
.purplishFade{
    background:rgba(0, 31, 63,.7);
}
.demoSlider{
    transition:.5s ease-in-out;
    position:relative;
    z-index:2;
}
.paginationContainer{
    margin-bottom:50px;
    margin-top:90px;
    width:100%;
}
.paginationContainer .comn_thumbs_thumb{
    opacity:.5 !important;
}
.paginationContainer .is-current{
    opacity:1 !important;
}
@media only screen and (max-width: 900px){
    .paginationContainer h4{
        font-size:25px;
    }
}
.imgPagination{
    display:flex;
    align-items:flex-end;
    padding:50px;
    padding-top:80px;
    padding-bottom:80px;
}
.sliderPaginationContainer{
    display:flex; 
    flex-flow:column;
    align-items: flex-end !important;
    margin-bottom:90px;
}

@media only screen and (max-width: 767px) {
    .sliderPaginationContainer{
        flex-flow:row !important;
        justify-content:center;
    }
    .circle{
        margin-left:10px;
        margin-right:10px;
    }
    .paginationContainer{
        display:none;
    }
}

.comn {
    position: relative;
    padding: 170px 0 50px;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transition: .6s ease-in-out;
    -o-transition: .6s ease-in-out;
    transition: .6s ease-in-out
}

.comn_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,31,63,.7);
    top: 0;
    left: 0;
}

.comn_slider-nav {
    margin: 30px 0
}

.comn_slider-nav_dot {
    margin-right: 20px
}

.comn_slider-nav_dot:last-of-type {
    margin-right: 0
}

.comn_slider-slide {
    height: inherit
}

.comn_slider-slide_content .title {
    font-size: 40px;
    line-height: 48px
}

.comn_slider-slide_content .text {
    font-size: 17px;
    line-height: 26px
}

.comn_slider-slide_content .btn {
    width: 100%
}

.comn_thumbs {
    margin-top: 70px;
    position: relative;
    overflow: hidden
}

.comn_thumbs-thumb {
    color: #fff
}

.comn_thumbs-thumb_inner {
    cursor: pointer;
    position: relative;
    height: 233px;
    padding: 40px;
    background-repeat: no-repeat;
    background-size: cover
}

.comn_thumbs-thumb_inner .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,31,63,.4);
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.comn_thumbs-thumb_inner .title {
    z-index: 1000;
    max-width: 213px
}

@media screen and (min-width:575.98px) {

    .comn_slider-slide_content .btn {
        width: unset
    }

    .comn_slider-nav {
        margin: 30px auto 30px 0
    }
}

@media screen and (min-width:767.98px) {

    .comn_slider-slide_content .title {
        font-size: 46px;
        line-height: 52px
    }
}

@media screen and (min-width:991.98px) {
    .comn {
        padding: 315px 0 60px
    }

    .comn_thumbs {
        margin-top: 167px
    }

    .comn_slider-nav_dot {
        margin-right: 0;
        margin-bottom: 20px
    }

    .comn_slider-nav_dot:last-of-type {
        margin-bottom: 0
    }

    .comn_slider-slide_content .title {
        font-size: 58px;
        line-height: 60px
    }

    .comn_slider-slide_content .text {
        margin: 20px 0 50px;
        max-width: 578px;
        font-size: 19px;
        line-height: 28px
    }
}


.comn_img {
    margin-top: 30px;
    overflow: hidden
}

.comn_img::before {
    float: left;
    padding-top: 106.06060606060605%;
    content: ''
}

.comn_img::after {
    display: block;
    content: '';
    clear: both
}

.comn_img-img {
    display: block;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.comn_header-list_item {
    font-weight: 500;
    color: #202c38;
    margin-bottom: 15px
}

.comn_header-list_item:last-of-type {
    margin-bottom: 0
}

.comn_header-list_item .icon {
    color: #de133b;
    font-size: 16px;
    margin-right: 10px
}

.comn_header .btn {
    width: 100%;
    margin-top: 40px
}

@media screen and (min-width:767.98px) {
    .comn_img {
        margin-top: 40px;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }
}

@media screen and (min-width:1199.98px) {
    .comn_header {
        max-width: 520px;
        padding: 80px 0;
        margin-right: 115px
    }

    .comn_img {
        margin-top: 0
    }

    .services_slider-slide .img-wrapper {
        min-height: 530px;
        overflow: hidden
    }
}

.quoteTitle {
    position: relative
}

.quoteTitle .author,.quoteTitle .lineTitle,.quoteTitle .title {
    position: relative;
    z-index: 2
}

.quoteTitle .author {
    text-transform: uppercase;
    color: #de133b;
    display: inline-block;
    margin-top: 40px
}

.quoteTitle-icon {
    -webkit-animation: 2s ease-in-out infinite both rotate-center;
    animation: 2s ease-in-out infinite both rotate-center;
    position: absolute;
    z-index: 1;
    top: -20%;
    right: 0
}

@media screen and (min-width:575.98px) {
    .comn_header .btn {
        width: unset
    }

    .quoteTitle-icon {
        top: -5%;
        right: 0
    }
}

@media screen and (min-width:991.98px) {
    .quoteTitle-icon {
        top: -66px;
        left: 0
    }
}

.comn_slider-nav button,.tns-nav button {
    width: 12px;
    height: 12px;
    background: #de133b;
    display: block;
    border-radius: 50%;
    position: relative;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out
}

.comn_slider-nav button:after,.tns-nav button:after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    border: 1px solid transparent;
    -webkit-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out
}

.comn_slider-nav button.tns-nav-active,.tns-nav button.tns-nav-active {
    background: #001f3f
}

.comn_slider-nav button.tns-nav-active:after,.tns-nav button.tns-nav-active:after {
    border-color: #001f3f
}

.comn_slider-nav--alt button.tns-nav-active,.tns-nav--alt button.tns-nav-active {
    background: #de133b
}

.comn_slider-nav--alt button.tns-nav-active:after,.tns-nav--alt button.tns-nav-active:after {
    border-color: #de133b
}
