.container-dashboard{
    background-size: cover;
}
.lightFade-dashboard{
    padding:20px;
}
.selected>*{
    visibility: hidden;
}
.unselected{
    background:rgba(13, 165, 116, .8);
    color:white;
}
