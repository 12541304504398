*{
    overflow:none;
}
.primary-bg {
    background: #000000;
    color: #fff
}

.primary-bg-force {
    background: #000000 !important;
    color: #fff
}

.brandLogo {
    width: 50px;
    margin-right: 20px;
    mix-blend-mode: difference; /* Blend the black color to white */
   /* Set the background color to white */
}


.header_navbar {
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100000;
    color: white;
}

.header_navbar-nav {
    width: 100%;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
}

.header_navbar-nav .link {
    display: inline-block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    line-height: .9
}

.header_navbar-nav_list {
    max-height: 0;
    margin-top: 0;
    overflow: hidden;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.header_navbar-nav_list.active {
    max-height: 90vh;
    margin-top: 30px;
    overflow-y: scroll
}

.header_navbar-nav_list .list-item {
    text-transform: uppercase;
    font-size: 14px;
    font-family: Archivo, sans-serif;
    font-weight: 600;
    padding: 20px 0
}

.header_navbar-nav_list .list-item .dropdown-content {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 0 0 15px
}

.header_navbar-nav_list .list-item .dropdown-content.active {
    max-height: 1000px;
    margin: 20px 0 0 15px
}

.header_navbar-nav_list .list-item .dropdown-content .link {
    margin-bottom: 20px
}

.header_navbar-nav_list .list-item .dropdown-content .link:last-of-type {
    margin-bottom: 0
}

.header_navbar-nav_list .list-item .dropdown-trigger {
    text-transform: inherit;
    font-size: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.header_navbar-nav_list .list-item .dropdown-trigger.active .icon {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.header_navbar-nav_list .list-item .dropdown-trigger .icon {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    display: inline-block;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    color: #de133b;
    margin: 0 10px
}

.header_extension {
    padding: 100px 0 40px;
    position: relative;
    overflow: hidden
}

.header_extension .section_header {
    position: relative;
    z-index: 3
}

.header_extension .plan {
    position: absolute;
    width: 50%;
    -webkit-transform: rotate(153deg);
    -ms-transform: rotate(153deg);
    transform: rotate(153deg);
    bottom: -22%;
    right: 10%;
    opacity: .8;
    z-index: 1
}

.header_extension .breadcrumbs {
    margin-top: 30px
}

.header_extension .breadcrumbs_item {
    font-weight: 500;
    position: relative;
    margin-right: 84px
}

.header_extension .breadcrumbs_item:hover:before {
    width: 100%
}

.header_extension .breadcrumbs_item:after,
.header_extension .breadcrumbs_item:before {
    content: '';
    position: absolute
}

.header_extension .breadcrumbs_item:after {
    width: 30px;
    height: 2px;
    background: #fff;
    top: 50%;
    right: -58px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.header_extension .breadcrumbs_item:before {
    width: 0;
    height: 2px;
    background-color: #de133b;
    bottom: -6px;
    -webkit-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out
}

.header_extension .breadcrumbs_item--current {
    font-weight: 400;
    padding: 0;
    margin: 0
}

.header_extension .breadcrumbs_item--current:after,
.header_extension .breadcrumbs_item--current:before {
    display: none
}

.hamburger {
    width: 30px;
    height: 28px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    position: relative;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.hamburger .line {
    width: 100%;
    height: 2px;
    background: #fff;
    -webkit-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    border-radius: 3px
}

.hamburger .line--short {
    width: 50%
}

.hamburger.active .line--short {
    width: 100%
}

.brand {
    display: inline-block;
    font-size: 28px;
    line-height: 22px;
    color: #fff;
    font-family: Archivo, sans-serif;
    font-weight: 700
}

.brand svg {
    margin-right: 10px
}

.brand .ternary {
    margin-left: 5px
}

.brand .bikal {
    margin-left: 5px;
    color: rgb(255, 255, 255)
}

/*Media queries*/
@media screen and (min-width:575.98px) {
    .header_extension .plan {
        bottom: -50%
    }
}

@media screen and (min-width:767.98px) {
    .header_navbar {
        padding: 15px 0
    }

    .header_navbar-nav_list.active {
        max-height: 1000px;
        overflow-y: unset
    }

    .header_extension .plan {
        bottom: -73%
    }
}

@media screen and (min-width:991.98px) {
    .header .brand {
        font-size: 0
    }

    .header_navbar {
        background: 0 0;
        padding: 60px 0 0
    }

    .header_navbar.sticky {
        padding: 20px 0;
        background: #001f3f
    }

    .header_navbar .hamburger {
        display: none
    }

    .header_navbar .link--single {
        position: relative
    }

    .header_navbar .link--single:before {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        background: #de133b;
        left: 0;
        bottom: -6px;
        -webkit-transition: width .3s ease-in-out;
        -o-transition: width .3s ease-in-out;
        transition: width .3s ease-in-out
    }

    .header_navbar .link--single.active:before,
    .header_navbar .link--single:focus:before,
    .header_navbar .link--single:hover:before {
        width: 100%
    }

    .header_navbar-nav {
        -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
        order: unset
    }

    .header_navbar-nav_list {
        max-height: unset;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-left: 30px
    }

    .header_navbar-nav_list .list-item {
        padding: 0;
        margin-right: 20px;
        font-size: 14px;
        font-family: Archivo, sans-serif;
        font-weight: 600
    }

    .header_navbar-nav_list .list-item:last-of-type {
        margin-right: 0
    }

    .header_navbar-nav_list .list-item .dropdown-content {
        position: absolute;
        width: unset;
        max-height: unset;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity .3s ease-in-out;
        -o-transition: opacity .3s ease-in-out;
        transition: opacity .3s ease-in-out;
        margin: 10px 0 0;
        padding: 15px;
        background: #001f3f
    }

    .header_navbar-nav_list .list-item .dropdown-content.visible,
    .header_navbar-nav_list .list-item .dropdown-content:hover {
        visibility: visible;
        opacity: 1
    }

    .header_navbar-nav_list .list-item .dropdown-content.active {
        margin: 10px 0 0
    }

    .header_navbar-nav_list .list-item .dropdown-content .link {
        margin-bottom: 20px;
        line-height: 22px
    }

    .header_navbar-nav_list .list-item .dropdown-content .link:last-of-type {
        margin-bottom: 0
    }

    .header_navbar-nav_list .list-item .dropdown-trigger {
        text-transform: inherit;
        font-size: inherit
    }

    .header_navbar-nav_list .list-item .dropdown-trigger:focus+.dropdown-content,
    .header_navbar-nav_list .list-item .dropdown-trigger:hover+.dropdown-content {
        visibility: visible;
        opacity: 1
    }

    .header_navbar-nav_list .list-item .dropdown-trigger:focus .icon,
    .header_navbar-nav_list .list-item .dropdown-trigger:hover .icon {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg)
    }

    .header_navbar-nav_list .list-item .dropdown-trigger .icon {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        display: inline-block;
        -webkit-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        color: #de133b;
        margin-left: 10px
    }

    .header_extension {
        padding: 200px 0 80px;
        position: relative;
        overflow: hidden
    }

    .header_extension .plan {
        bottom: -62%
    }

    .header_extension .breadcrumbs {
        margin-top: 30px
    }
}

@media screen and (max-width:1199.98px){
    .brandText{
        display:none;
    }
}
@media screen and (min-width:1199.98px) {
    

    .header .brand {
        font-size: 28px
    }

    .header_extension {
        padding: 200px 0 80px;
        position: relative;
        overflow: hidden
    }

    .header_extension .plan {
        width: 651px;
        bottom: -82%;
        right: 128px
    }

    .header_extension .breadcrumbs {
        margin-top: 30px
    }


}

@media screen and (min-width:1399.98px) {
    #scrollToTop {
        width: 58px;
        height: 58px;
        right: 30px
    }

    #scrollToTop.active {
        bottom: 30px
    }

    .header_navbar-nav_list {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .header_navbar-nav_list .list-item {
        margin-right: 40px
    }
}

.nav-bar {
    display: flex;
    padding-right: 15px;
}

.nav-bar li {
    position: relative;
    margin-left: 40px;
}

.nav-bar li p , .dropdown-page-menu p {
    font-weight: bold;
    font-size: 16px;
}

.nav-bar li p:hover , .dropdown-page-menu p:hover {
    color: #de133b;
}
.dropdown-page-menu p{
    margin-top:20px;
    margin-bottom:20px;
}

.activeNav {
    pointer-events: none;
}

.activeNav::before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 25px;
    height: 2px;
    width: 100%;
    background: #de133b;
}

.activeNavSml {
    color: #de133b !important;
}

.nav-bar .dropDownIcon {
    margin-left: 5px;
    position: absolute;
    top: 5px;
    color: #de133b;
}
.navBarIcon{
    font-size:40px;  
    cursor:pointer;
    float:right;
    margin-top:20px;
    margin-right:10%;
    color:white;
}
.smallNav{
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:4 !important;
    transition:.5s ease-in-out;
}
.smallNav>.displayFlex{
    justify-content: space-between;
    padding:20px;
    padding-left:10%;
    padding-right:10%;
}
@media screen and (max-width:991.98px) {
    .nav-bar {
        display: none;
    }

    .brand {
        margin-top: 5px;
        margin-bottom: 5px; 
    }
    .header_navbar{
        display:none;
    }
    
}
@media screen and (max-width:991.98px) {
    .brandLogo{
        display:none;
    }
    .brandText{
        display:block;
        margin-top:3px !important;
    }
}
@media screen and (min-width:991.98px) {
    .goTop {
        margin-top: -35px;
        padding-bottom: 15px;
    }

    .smallNav {
        display: none;
    }

    .smallNavUl {
        display: none;
    }
}


.compressed{
    transform:scaleY(0);
}
.released{
    transform:scaleY(1);
}

.active{
    opacity: 1;
    visibility:visible;
    transform:translateY(0px);
    transition:.5s ease-in-out;

}
.inactive{
    opacity: 0;
    visibility: hidden;
    height:0 !important;
    transition:.5s ease-in-out;
} 
.smlNav{
    padding:30px;
    margin-top:81px;
    overflow:auto;
}
.smlNav>*{
    margin-top:20px;
    font-weight:bold;
    font-size:16px;
}
.activeNavSml{
    color:#de133b;
}
.textDiv{
    position:fixed;
    top:0;
    left:0;
    right:0;
    margin-bottom:1000px;
}
.invisible{
    display:none;
}
.visible{
    display:block;
}
.dropdown-page-menu{
    position: absolute;
    height:130px !important;
    padding:20px;
    top:100px;
    right:70px;    
    transform-origin:top;
    transition:.25s ease-in-out;
}
.scrollDown{
    transform:scaleY(1);
    transition: .25s ease-in-out;
}
.scrollUp{
    transform:scaleY(0);
    transition: .25 ease-in-out;
}
.scrollUpDropDown{
    display:none;
    overflow:scroll;
}
.scrollDownDropDown{
    display:block;
}