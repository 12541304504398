

/* Example styles for Swiper container */
.swiper-container {
    width: 100%;
    height: 400px; /* Adjust height as needed */
  }
  
  /* Example styles for Swiper slide */
  .feedback_slider-slide {
    /* Your custom styles */
  }
  
  /* Example styles for Swiper navigation arrows */
  .swiper-button-prev,
  .swiper-button-next {
    font-size: 24px;
    color: #fff;
    background-color: #000000;
    padding: 10px;
    border-radius: 50%;
  }
  
  /* Example styles for Swiper pagination bullets */
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #ccc;
    opacity: 0.7;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  /* Active pagination bullet style */
  .swiper-pagination-bullet-active {
    background-color: #007bff;
    opacity: 1;
  }

  
  .feedback_slider-slide_author {
    display: flex;
    align-items: center;
  }
  
  .feedback_slider-slide_author img {
    height: 150px;
    width: 140px;
    /* border-radius: 50%; */
    margin-right: 10px;
  }
  
  .feedback_slider-slide_author .wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .feedback_slider-slide_author .name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .feedback_slider-slide_author .company {
    font-size: 14px;
    color: #777;
  }

  
  .feedback_slider-slide_text {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }

  
  .feedback_slider-slide {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  