#map {
  width: 100%;
  height: 500px;
}
@media only screen and (min-width: 1380px){
  .contact_map #map{
    margin-left:120px;  
  }
}
  
