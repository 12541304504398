.dropDownMenu-dashboard{
    position:absolute;
    top:85px;
    left:0;
    right:0;
    padding:30px;
}
.dropDownMenu-dashboard p{
    margin-top:20px;
    cursor:pointer;
}

@media screen and (min-width:991.98px){
    .dropDownMenu-dashboard{
        display:none;
    }
}