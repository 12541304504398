
.loading-bg {
    
    height: 100vh; /* This will make the background take the full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-container {
    position: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
