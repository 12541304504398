.aboutMini{
    margin-top:0 !important;
    padding-top:100px !important
}
.comn_img>.img-fluid{
    width:100%;
}
.aboutMini .comn_header{
    padding-right:20px;
}
@media screen and (max-width:991.98px){
    .comn_img{
        display:none !important;
    }
}