.services_list-item[data-order="1"] {
    margin-top: 0
}

.services_list-item .wrapper {
    height: 100%;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    padding: 30px;
    -webkit-transition: border-color .3s ease-in-out;
    -o-transition: border-color .3s ease-in-out;
    transition: border-color .3s ease-in-out
}

.services_list-item .wrapper:hover {
    cursor:pointer;
    border:1px solid #de133b;
}



.services_list-item .wrapper .number {
    color: #de133b;
    font-family: Archivo,sans-serif;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px
}

.services_list-item .wrapper .title {
    margin: 20px 0
}

.services_list-item .wrapper .description {
    margin-bottom: 40px;
    color: #202c38
}

@media screen and (min-width:575.98px) {
    .services_list-item .wrapper {
        padding: 40px
    }

    .services_list-item .wrapper .number {
        font-size: 40px;
        line-height: 50px
    }
}

@media screen and (min-width:767.98px) {
    .services_list {
        margin: 0 -15px
    }

    .services_list-item {
        padding: 0 15px
    }

    .services_list-item[data-order="2"] {
        margin-top: 0
    }

    .services_list-item .wrapper {
        padding: 30px
    }
}

@media screen and (min-width:991.98px) {
    .services_list {
        margin: 0 -15px
    }

    .services_list-item {
        padding: 0 15px;
        margin-top: 30px
    }

    .services_list-item .wrapper {
        padding: 40px
    }

    .services_list-item .wrapper .number {
        font-size: 48px;
        line-height: 55px
    }
}
