.systemStructure-Form{
    padding-left:10%;
    padding-right:10%;
}
.systemStructure-Form button{
    margin-top:30px;
    margin-bottom:30px;
}
.systemStructure-Form > p{
    margin-top:50px;
}
.systemStructure-Form > h5{
    margin-top:10px;
}
.systemStructure-Form > input{
    border:1px solid rgb(197, 197, 197);
    height:50px;
    width:100%;
    margin-top:10px;
    font-size:25px;
    padding-left:20px;
}