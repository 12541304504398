.about_header{
    white-space: pre-line;
}
.about_header .text {
    margin: 30px 0 40px
}

.about_header-list_item {
    margin-bottom: 30px
}

.about_header-list_item:last-of-type {
    margin-bottom: 0
}

.about_header-list_item .icon {
    max-width: 40px;
    height: 40px;
    margin-right: 30px;
    display: block;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.-list_item .icon * {
    fill: #de133b
}

.about_header-list_item .label {
    display: block;
    font-weight: 500;
    max-width: 255px
}

.about_header .btn {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 50px
}

.about_numbers-group_group:first-of-type {
    margin-bottom: 20px
}

.about_numbers-group_group .label,.about_numbers-group_group .number {
    display: block
}

.about_numbers-group_group .number {
    font-family: Archivo,sans-serif;
    font-weight: 800;
    margin-bottom: 5px;
    color: #de133b
}

.about_numbers-group_group .label {
    font-weight: 500
}

.about_numbers-img {
    margin-top: 40px;
    max-height: 461px;
    background-size: cover;
    height: 100%;
    overflow: hidden
}

.about_numbers-img_img {
    width: 100%;
    height: 100%;
    display: block;
    -o-object-fit: cover;
    background-size: cover;
    object-fit: cover
}

@media screen and (min-width:575.98px) {
    .about_header .btn {
        width: unset
    }

    .about_numbers-group_group {
        max-width: 203px;
        text-align: left
    }

    .about_numbers-group_group:first-of-type {
        margin-bottom: 0;
        margin-right: 60px
    }
}

@media screen and (min-width:991.98px) {
    .about_header .title {
        width: 526px
    }

    .about_header .btn {
        margin-top: 50px;
        margin-bottom: 0
    }

    .about_numbers-group_group .number {
        font-size: 80px;
        line-height: 80px
    }

    .about_numbers-img {
        margin-top: 30px
    }
}

@media screen and (min-width:1199.98px) {
    .about_numbers-img {
        margin-top: 44px;
        max-height: 461px
    }
}

.about_numbers-group_group h2{
    font-size:62px !important;
}
