.errorPageDisplay {
    text-align: center;
    min-height: 100vh;
    padding-top: 40px
}

.errorPageDisplay_number {
    font-family: Archivo,sans-serif;
    font-weight: 800;
    font-size: 120px;
    line-height: 1;
    display: inline-block;
    text-align: left;
    margin-bottom: 30px
}

.errorPageDisplay_message-header {
    text-align: left
}

.errorPageDisplay_message-header .lineTitle {
    text-transform: uppercase;
    font-family: Archivo,sans-serif;
    font-weight: 600;
    padding-left: 60px;
    position: relative;
    margin-bottom: 12px;
    display: inline-block
}

.errorPageDisplay_message-header .lineTitle:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 40px;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #de133b
}

.errorPageDisplay_message-text {
    margin: 18px 0 30px;
    text-align: left
}

.errorPageDisplay_message .btn {
    width: 100%
}

@media screen and (min-width:575.98px) {
    .errorPageDisplay_message {
        max-width: 230px
    }

    .errorPageDisplay_number {
        text-align: center
    }
}

@media screen and (min-width:767.98px) {
    .errorPageDisplay {
        text-align: left;
    }

    .errorPageDisplay_number {
        font-size: 200px;
        margin-right: 62px;
        margin-bottom: 0
    }

    .errorPageDisplay_message .btn {
        width: auto;
        margin-right: auto
    }
}

@media screen and (min-width:991.98px) {

    .errorPageDisplay_number {
        font-size: 320px;
        margin-bottom: -30px
    }
}
