.advantages_container {
    display: flex;
}

.advantages_header-list {
    margin: 30px 0 40px;
    padding: 40px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
}
.advantages_header-list-no_color{
    color:white;
}

.advantages_header-list_item {
    margin-bottom: 15px
}

.advantages_header-list_item:last-of-type {
    margin-bottom: 0
}

.advantages_header-list_item .label,
.advantages_header-list_item .number {
    display: block
}

.advantages_header-list_item .number {
    color: #de133b;
    margin-bottom: 5px;
    font-family: Archivo, sans-serif;
    font-weight: 700
}

.advantages_header-list_item .label {
    color: #202c38;
    font-weight: 500
}
.advantages_header-list-no_color .label{
    color: white !important;
}
.advantages_header-list-no_color .number{
    color:white !important;
    font-size:48px;
}

.advantages_list-list {
    margin-top: 40px
}

.advantages_list-list_item {
    margin-bottom: 30px
}

.advantages_list-list_item:last-of-type {
    margin-bottom: 0
}

.advantages_list-list_item .number {
    color: #c8c8c8;
    font-family: Archivo, sans-serif;
    font-weight: 800;
    margin-right: 20px
}

.advantages_list-list_item .title {
    margin-bottom: 10px
}

@media screen and (min-width:575.98px) {
    .advantages_header-list_item .number {
        margin-bottom: 10px
    }

    .advantages_header-list_item .label {
        max-width: 170px
    }
}

@media screen and (min-width:767.98px) {
    .advantages_header-list {
        margin: 40px 0
    }

    .advantages_header-list_item {
        width: calc(50% - 15px)
    }
}

@media screen and (min-width:991.98px) {
    .advantages_header {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    .advantages_header-list_item {
        width: unset;
        margin: 0
    }
}

@media screen and (max-width:991.98px) {
    .advantages_container {
        display: block;
    }
}

@media screen and (min-width:991.98px) {

    .advantages_header {
        padding-right: 40px;
    }
}

@media screen and (min-width:1199.98px) {
    .advantages_header {
        max-width: 600px;
        margin-right: 60px
    }

    .advantages_header-list {
        padding: 80px;
        margin: 40px 0 0
    }

    .advantages_header-list_item:first-of-type,
    .advantages_header-list_item:nth-of-type(2) {
        margin-bottom: 60px
    }

    .advantages_list {
        padding: 30px 0
    }
}