.singlePostContainer{
    margin-top:80px;
}
.singlePostContainer .contactForm-form{
    margin-bottom:120px;
}
.blogArticleFull{
    margin-bottom:90px;
}
.image-singleBlog{
    margin-bottom:30px;
    width:100%;
}
.article-singleBlog{
    margin-bottom:90px;
}
@media screen and (min-width:991.98px){
    .singleBlogContainer{
        padding-right:80px;
        font-size:16px;
    }
}