* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0
}

html {
    scroll-behavior: smooth
}

body,html {
    overflow-x: hidden
}

ul {
    list-style: none
}

a {
    text-decoration: none
}
a,button,input,textarea {
    background: 0 0;
    color: inherit;
    font-family: inherit;
    border: none;
    cursor: pointer
}
textarea {
    resize: none
}
