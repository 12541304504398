.designImage {
    width: 100%;
    position: absolute;
    top: -200px; /* Default position for desktop view */

    /* Media query for mobile view */
    @media (max-width: 767px) {
        top: 0px; /* Position for mobile view */
    }
}

.imgContainer{
    display:flex;
    justify-content: center;

}
