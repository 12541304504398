.numbers_list-item {
    margin-bottom: 25px
}

.numbers_list-item:last-of-type {
    margin-bottom: 0
}

.numbers_list-item .number {
    margin-bottom: 10px;
    font-family: Archivo,sans-serif;
    font-weight: 800
}

.numbers_list-item .label {
    font-weight: 500
}

.fourStatsContainer{
    margin-bottom:50px !important;
}