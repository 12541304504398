.contact>.container {
    display: flex;
}




.contact-info_group {
    font-family: Archivo, sans-serif;
    margin-bottom: 30px
}

.contact-info_group:last-of-type {
    margin-bottom: 0
}

.contact-info_group span {
    display: block
}

.contact-info_group .name {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
    color: #a9a9a9;
    font-weight: 500
}

.contact-info_group .content {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px
}

.contact-info_group .content .link {
    -webkit-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out
}

.contact-info_group .content .link:focus,
.contact-info_group .content .link:hover {
    color: #de133b
}

@media screen and (min-width:575.98px) {
    .contact .mediaLink_item-link {
        width: 50px;
        height: 50px;
        font-size: 26px;
        line-height: 30px
    }
}

.contact {
    padding-top: 0
}

.contact_header .text {
    margin: 30px 0 40px
}

.contact_map {
    margin-top: 40px;
    height: 350px
}

.contact_map #map {
    width: 100%;
    height: 100%
}

.contactForm-header {
    margin-bottom: 50px;
}

@media screen and (min-width:991.98px) {
    .contact-info {
        margin-bottom: 87px
    }

    .contact-info_group {
        margin-bottom: 40px
    }

    .contact_map {
        margin-top: 100px;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 30px;
    }

    .smallMap {
        display: none;
    }
}

@media screen and (max-width:991.98px) {
    .finalPhone {
        margin-bottom: 50px;
    }
    .contact>.container{
        flex-flow:column;
    }
}

@media screen and (min-width:1399.98px) {
    .contact-wrapper {
        max-width: 500px
    }

    .contact_map {
        max-width: 660px;
        height: 543px;
        margin-left: 0
    }
}



.contact-form_btn,
.contact-form_field {
    width: 100%
}

.contact-form_field {
    font-size: inherit;
    line-height: inherit;
    padding: 17px 20px;
    border: 1px solid #a9a9a9;
    margin-bottom: 30px;
    color: #202c38;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.contact-form_field[data-type=message] {
    margin-bottom: 0;
    min-height: 164px
}

.contact-form_field::-webkit-input-placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field::-moz-placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field:-ms-input-placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field::-ms-input-placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field::placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field:focus {
    border-color: #202c38
}

.contact-form_field:focus::-webkit-input-placeholder {
    opacity: 0
}

.contact-form_field:focus::-moz-placeholder {
    opacity: 0
}

.contact-form_field:focus:-ms-input-placeholder {
    opacity: 0
}

.contact-form_field:focus::-ms-input-placeholder {
    opacity: 0
}

.contact-form_field:focus::placeholder {
    opacity: 0
}

.contact-form_btn {
    margin-top: 40px;
}

.contact-info {
    max-width: 285px;
    margin-bottom: 40px
}

.contact-info_group {
    font-family: Archivo, sans-serif;
    margin-bottom: 30px
}

.contact-info_group:last-of-type {
    margin-bottom: 0
}

.contact-info_group span {
    display: block
}

.contact-info_group .name {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
    color: #a9a9a9;
    font-weight: 500
}

.contact-info_group .content {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px
}

.contact-info_group .content .link {
    -webkit-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out
}

.contact-info_group .content .link:focus,
.contact-info_group .content .link:hover {
    color: #de133b
}

@media screen and (min-width:575.98px) {
    .contact-form_field--half {
        width: calc(50% - 15px)
    }

    .contact .mediaLink_item-link {
        width: 50px;
        height: 50px;
        font-size: 26px;
        line-height: 30px
    }
}

@media screen and (min-width:991.98px) {
    .contact-info {
        margin-bottom: 87px
    }

    .contact-info_group {
        margin-bottom: 40px
    }
}

.contact_header .text {
    margin: 30px 0
}

.contact_info {
    margin-bottom: 30px
}

.contact .wrapper--bg {
    margin-top: 50px;
    background-color: #f8f8f8;
    padding: 30px
}

@media screen and (min-width:767.98px) {
    .contact_info {
        margin-bottom: 40px
    }

    .contactForm {
        padding-right: 90px;
    }
}

@media screen and (max-width:767.98px) {
    .contact-form_btn {
        margin-bottom: 90px;
    }
}

.contacts_map {
    height: 330px
}

.contacts_map #map {
    height: 100%
}

@media screen and (min-width:991.98px) {
    .contact_header .text {
        margin: 20px 0 30px
    }

    .contact .wrapper--bg {
        margin-top: 0;
        padding: 60px
    }

    .contacts_map {
        height: 660px
    }
}
.contact_info-header{
    margin-bottom:30px;
}
.contact-faq{
    margin-top:150px;
}



.dk-footer .footer-widget p {
    margin-bottom: 27px;
}
.dk-footer  {
    font-family: 'Nunito', sans-serif;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    font-size: 16px;
    line-height: 28px;
}

.dk-footer .animate-border {
  position: relative;
  display: block; 
  width: 115px;
  height: 3px;
  background: rgb(0, 0, 0); }

.dk-footer .animate-border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 0;
  bottom: 0;
  border-left: 10px solid #ffffff;
  border-right: 10px solid #ffffff;
  -webkit-animation: animborder 2s linear infinite;
  animation: animborder 2s linear infinite; }

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px); } }

@keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px); } }

.dk-footer .animate-border .border-white:after {
  border-color: #ffffff; }

.dk-footer .animate-border .border-yellow:after {
  border-color: rgb(255, 255, 255); }

.dk-footer .animate-border.dk-footer .border-orange:after {
  border-right-color: #007bff;
  border-left-color: #007bff; }

.dk-footer .animate-border .border-ash:after {
  border-right-color: #000000;
  border-left-color: #000000; }

.dk-footer .animate-border .border-offwhite:after {
  border-right-color: #000000;
  border-left-color: #000000; }

/* Animated heading border */
@keyframes primary-short {
  0% {
    width: 15%; }
  50% {
    width: 90%; }
  100% {
    width: 10%; } }

@keyframes primary-long {
  0% {
    width: 80%; }
  50% {
    width: 0%; }
  100% {
    width: 80%; } } 

.dk-footer {
  padding: 75px 0 0;
  background-color: white;
  position: relative;
  z-index: 2; }
  .dk-footer .contact-us {
    margin-top: 0;
    margin-bottom: 30px;
    padding-left: 80px; }
    .dk-footer .contact-us .contact-info {
      margin-left: 50px; }
    .dk-footer .contact-us.contact-us-last {
      margin-left: -80px; }
 

 .footer-social-link h3 {
    color: #000000;
    font-size: 24px;
    margin-bottom: 25px; }
  .footer-social-link ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .footer-social-link li {
    display: inline-block; }
  .footer-social-link a i {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background: #000;
    margin-right: 5px;
    color: #fff; }
     .footer-social-link a .icon-facebook {
      background-color: #3B5998; }
    .footer-social-link a i.fa-twitter {
      background-color: #55ACEE; }
    .footer-social-link a i.fa-linkedin {
      background-color: #0976B4; }
    .footer-social-link a .icon-whatsapp{
      background-color: #075e54; }

.footer-awarad {
  margin-top: 285px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .footer-awarad p {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    margin-left: 20px;
    padding-top: 15px; }

.footer-info-text {
  margin: 26px 0 32px;
  color: black
 }

.footer-left-widget {
  padding-left: 80px; }

.footer-widget .section-heading {
  margin-bottom: 35px; }

.footer-widget h3 {
  font-size: 24px;
  color: #000000;
  position: relative;
  margin-bottom: 15px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }

.footer-widget ul {
  width: 50%;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0; }

.footer-widget li {
  margin-bottom: 18px; }

.footer-widget p {
  margin-bottom: 27px; }

.footer-widget a {
  color: #000000;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .footer-widget a:hover {
    color: #1ead0b; }

.footer-widget:after {
  content: "";
  display: block;
  clear: both; }

.dk-footer-form {
  position: relative; }
  .dk-footer-form input[type=email] {
    padding: 14px 28px;
    border-radius: 50px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E; }
  .dk-footer-form input::-webkit-input-placeholder, .dk-footer-form input::-moz-placeholder, .dk-footer-form input:-ms-input-placeholder, .dk-footer-form input::-ms-input-placeholder, .dk-footer-form input::-webkit-input-placeholder {
    color: #878787;
    font-size: 14px; }
  .dk-footer-form input::-webkit-input-placeholder, .dk-footer-form input::-moz-placeholder, .dk-footer-form input:-ms-input-placeholder, .dk-footer-form input::-ms-input-placeholder, .dk-footer-form input::placeholder {
    color: #878787;
    font-size: 14px; }
  .dk-footer-form button[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px 24px 12px 17px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: 1px solid #007bff;
    background: #007bff;
    color: #000000; }
  .dk-footer-form button:hover {
    cursor: pointer; }

/* ==========================

    Contact

=============================*/
.dk-footer .contact-us {
  position: relative;
  z-index: 2;
  margin-top: 65px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.dk-footer .contact-icon {
  position: absolute; 
}
  .dk-footer .contact-icon .icon {
    font-size: 36px;
    top: -40px;
    position: relative;
    color: #000000; 
    cursor:pointer;
}

.dk-footer .contact-info {
  margin-left: 75px;
  color: #000000; }
  .dk-footer .contact-info h3 {
    font-size: 20px;
    color: #000000;
    margin-bottom: 0; }

.dk-footer .copyright {
  padding: 28px 0;
  margin-top: 55px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
  background-color: #ffffff; }
  .dk-footer .copyright span,
  .dk-footer .copyright a {
    color: #878787;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear; }
  .dk-footer .copyright a:hover {
    color:#007bff; }

.dk-footer .copyright-menu ul {
  text-align: right;
  margin: 0; }

.dk-footer .copyright-menu li {
  display: inline-block;
  padding-left: 20px; }

.dk-footer .back-to-top {
  position: relative;
  z-index: 2; }
  .dk-footer .back-to-top .btn-dark {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 0;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #2e2e2e;
    border-color: #2e2e2e;
    display: none;
    z-index: 999;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .dk-footer .back-to-top .btn-dark:hover {
      cursor: pointer;
      background: #FA6742;
      border-color: #FA6742; }

      @media screen and (max-width:767.98px) {
        .dk-footer .contact-us{
            display:flex;
            justify-content: center;
            align-items: center;
            padding:0px;
        }
        .dk-footer .contact-us h3{
            margin-top:10px;
        }
        .dk-footer .contact-us > *{
            margin:0;
        }
        .dk-footer .contact-us-last{
            margin-left:0px !important;
            margin-top:20px;
        }
    }
    @media screen and (max-width:991.98px) {
        .footer-widget{
            padding-left:50px;
            margin-top:30px;
        }
    }