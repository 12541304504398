.blogContainer-blogs{
    margin-top:100px;
}
.blogContainer-blogs .link{
    margin-top:20px;
}
.blogContainer-blogs .col-lg-8{
    padding-bottom:120px;
}
.blogContainer-blogs .paginationContainer-blogs{
    position:relative;
}
.blogContainer-child{
    margin-bottom:50px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
}
.blogTitle{
    margin-bottom:20px;
}
.blogArticle{
    margin-bottom:20px;
}
.blog-view-image{
    margin-bottom:20px;
    width:100%;
    height:40%;
    margin-top:20px;
}
.pageContainer{
    position: absolute;
    left:0;
    right:0;
    display:flex;
    justify-content: space-between;
    padding:20px;
}
.activeCls{
    font-weight: bold;
}
.iconArrow-blogs{
    font-size:25px;
}
.blogContainer-blogs .articleContainer-blogs{
    height:100px;
    padding-right:20px;
    overflow:hidden;
}
.blogContainer-blogs .blogDate{
    font-size:14px;
    color:rgb(175, 175, 175);
    margin-bottom:5px;
}
.blogContainer-blogs .blogTitle{
    /* height:95px; */
    overflow:hidden;
}
.blogContainer-blogs .horizontalLine{
    margin-top:20px;
    color:rgb(243, 243, 243);
    height:1px;
    width:92%
}
