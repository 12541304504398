/* skillsSection.css */

.client__area-title {
  max-width: 620px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 60px;
}

.client__area .row .col-xl-3,
.client__area .row .col-lg-3,
.client__area .row .col-md-6,
.client__area .row .col-sm-6 {
  margin-bottom: 20px;
}

.client__area .client__area-item {
  /* Add fixed height and width to the container */
  width: 200px;
  height: 200px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
}

.client__area .client__area-item img {
  /* Make the images fit inside the fixed-size container */
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}


.client__area-title .lineTitle {
  text-transform: uppercase;
  padding-left: 60px;
  position: relative;
  display: inline-block;
  font-size: 25px;
  line-height: 21px;
  margin-bottom: 15px;
  font-family: Archivo,sans-serif;
  font-weight: 600
}

.client__area-title .lineTitle:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 2px;
  width: 40px;
  background: #de133b
}

.client__area-title .lineTitle--extended {
  padding-left: 20px;
  margin-left: 40px
}

.client__area-title .lineTitle--extended:before {
  width: 1000%;
  left: -1000%
}